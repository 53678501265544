// Track failed chunks to avoid endless reload loops
const FAILED_CHUNKS_KEY = 'vite-failed-chunks';
let failedChunks = {};

// Initialize the chunk error handler
export function initChunkErrorHandler() {
  // Try to load any previously failed chunks from sessionStorage
  try {
    const stored = sessionStorage.getItem(FAILED_CHUNKS_KEY);
    if (stored) {
      failedChunks = JSON.parse(stored);
    }
  } catch (e) {
    console.warn('Could not access sessionStorage:', e);
  }

  // Register global Vite chunk error handler
  window.addEventListener('vite:preloadError', (event) => {
    const error = event.payload;
    console.warn('Failed to load chunk:', error);

    // Extract the chunk URL from the error
    let chunkUrl = '';
    if (error && error.message) {
      const match = error.message.match(/https:\/\/[^'"\s]+/);
      if (match) {
        chunkUrl = match[0];
      }
    }

    // Prevent the error from propagating
    event.preventDefault();

    // Check if this is a repeated failure
    const now = Date.now();
    const BACKOFF_TIME = 30000; // 30 seconds

    if (
      chunkUrl &&
      failedChunks[chunkUrl] &&
      now - failedChunks[chunkUrl].timestamp < BACKOFF_TIME
    ) {
      failedChunks[chunkUrl].count++;

      // If we've tried multiple times recently, wait for deployment to finish
      if (failedChunks[chunkUrl].count > 2) {
        console.info(
          'Multiple chunk loading failures detected. Likely during deployment. Waiting before retry...',
        );

        // Create a deployment-in-progress notification
        const deploymentNotice = document.createElement('div');
        deploymentNotice.style.position = 'fixed';
        deploymentNotice.style.top = '0';
        deploymentNotice.style.left = '0';
        deploymentNotice.style.right = '0';
        deploymentNotice.style.padding = '10px';
        deploymentNotice.style.backgroundColor = '#fcf8e3';
        deploymentNotice.style.color = '#8a6d3b';
        deploymentNotice.style.textAlign = 'center';
        deploymentNotice.style.zIndex = '9999';
        deploymentNotice.innerHTML = `
          <p>Deployment in progress. Application will refresh automatically when ready. 
          <button id="force-refresh">Refresh Now</button></p>
        `;

        document.body.appendChild(deploymentNotice);

        document
          .getElementById('force-refresh')
          .addEventListener('click', () => {
            sessionStorage.removeItem(FAILED_CHUNKS_KEY);
            window.location.href = `/?force_refresh=${Date.now()}`;
          });

        // Set up a periodic check to see if deployment is complete
        const checkInterval = setInterval(() => {
          // Use a fetch with cache busting to check for the specific chunk
          fetch(`${chunkUrl}?check=${Date.now()}`, { cache: 'no-store' })
            .then((response) => {
              if (response.ok) {
                clearInterval(checkInterval);
                console.info('Chunk now available. Reloading application...');
                sessionStorage.removeItem(FAILED_CHUNKS_KEY);
                window.location.reload();
              }
            })
            .catch(() => {
              // Still not available, continue waiting
            });
        }, 5000); // Check every 5 seconds

        return;
      }
    } else {
      // First time seeing this chunk fail or it's been a while
      failedChunks[chunkUrl] = {
        timestamp: now,
        count: 1,
      };
    }

    // Save failed chunks to sessionStorage
    try {
      sessionStorage.setItem(FAILED_CHUNKS_KEY, JSON.stringify(failedChunks));
    } catch (e) {
      console.warn('Could not save to sessionStorage:', e);
    }

    // Log for the user
    console.info('Reloading page to recover from chunk loading error...');

    // Reload the page after a short delay with cache busting
    setTimeout(() => {
      window.location.href = `${window.location.pathname}?reload=${Date.now()}`;
    }, 1000);
  });
}
